<template>
  <q-table flat bordered :data="affiliates" row-key="id" :columns="columns" :pagination="pagination">
    <template v-slot:bottom>
      <!-- <pagination-table
              @change="getAffiliates"
              :data="reqPagination"
              :pagination="pagination"
              :gateway="false"
            /> -->
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        Total:{{ reqPagination.total }}
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
    <template v-slot:body-cell-created_at="props">
      <q-td :props="props">
        {{ $moment(props.value).format('LL') }}
      </q-td>
    </template>
    <template v-slot:body-cell-comercial_manager_id="props">
      <q-td>
        <q-select
          v-model="props.row.comercial_manager_id"
          :options="managerOptions"
          borderless
          option-label="name"
          emit-value
          option-value="user_id"
          map-options
          @input="(value) => editAffiliateComercialManager(props.row.id, value)"
          :display-value="
            props.row.comercial_manager_id == 0
              ? 'Não selecionado'
              : managerOptions.find((item) => {
                  item.user_id == props.row.comercial_manager_id;
                })
          "
      /></q-td>
    </template>

    <template v-slot:body-cell-service_manager_id="props">
      <q-td>
        <q-select
          v-model="props.row.service_manager_id"
          :options="managerOptions"
          borderless
          option-label="name"
          emit-value
          option-value="user_id"
          map-options
          @input="(value) => editAffiliateServiceManager(props.row.id, value)"
          :display-value="
            props.row.service_manager_id == 0
              ? 'Não selecionado'
              : managerOptions.find((item) => {
                  item.user_id == props.row.service_manager_id;
                })
          "
        />
      </q-td>
    </template>
    <template v-slot:body-cell-action="props">
      <q-td :props="props">
        <q-btn
          unelevated
          round
          flat
          icon="mdi-thumb-up-outline"
          color="primary"
          @click="updateStatusAffiliate(props.row, 3)"
        >
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Aprovar </q-tooltip>
        </q-btn>
        <q-btn unelevated round flat icon="mdi-pause" color="primary" @click="updateStatusAffiliate(props.row, 4)">
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Pausar </q-tooltip>
        </q-btn>
        <q-btn
          unelevated
          round
          flat
          icon="mdi-pencil-outline"
          color="primary"
          :to="`/affiliate/manager/${props.row.id}`"
        >
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
            {{ $t('edit') }}
          </q-tooltip>
        </q-btn>

        <q-btn
          unelevated
          round
          flat
          icon="mdi-notebook-outline"
          color="primary"
          @click="ActionSetDialogAffiliate({ value: props.row })"
        >
          <!-- :icon="props.row.id == '11' ? 'mdi-notebook-plus-outline' : 'mdi-notebook-check-outline'" -->
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Informações </q-tooltip>
        </q-btn>

        <q-btn
          unelevated
          round
          flat
          icon="mdi-trash-can-outline"
          color="negative"
          @click="updateStatusAffiliate(props.row, 5)"
        >
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Desativar </q-tooltip>
        </q-btn>
      </q-td>
    </template>
    <template v-slot:body-cell-status="props">
      <q-td :props="props">
        <q-chip v-if="props.value == 1" color="blue" text-color="white" label="Pendente" />
        <q-chip v-else-if="props.value == 3" color="positive" text-color="white" label="Ativo" />
        <q-chip v-else-if="props.value == 4" color="warning" text-color="white" label="Pausado" />
        <q-chip v-else-if="props.value == 5" color="negative" text-color="white" label="Desativado" />
        <q-chip v-else color="blue" text-color="white" label="Pendente" />
      </q-td>
    </template>
  </q-table>
</template>
<script>
import AffiliateMixin from '../../mixins/AffiliateMixin';
import AffiliateService from '../../services/AffiliateService';

export default {
  name: 'ListAffiliate',
  mixins: [AffiliateMixin, AffiliateService],
  data() {
    return {
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      },
      managerOptions: []
    };
  },

  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getAffiliates(`page=${this.currentPage}`, `%26perPage=${this.pagination.rowsPerPage}`);
      }
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  created() {
    this.getAffiliates(null, '%26perPage=' + this.pagination.rowsPerPage);
    this.getAffiliateManagerOptions();
  },

  methods: {
    async updateStatusAffiliate(row, statusEdit) {
      this.onLoading(true);
      try {
        const { data, status } = await this.updateAffiliateStatus(row.id, statusEdit);
        if (status === 200 || status === 201) {
          this.getAffiliates();

          this.successNotify(data.message);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    async getAffiliateManagerOptions() {
      try {
        this.onLoading(true);
        const { data } = await this.$http.get('user/affiliate/managers');
        this.managerOptions = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>
