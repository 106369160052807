import { mapActions, mapGetters } from 'vuex';

export default {
  methods: {
    ...mapActions('Channel', [
      'ActionSetChannels',
      'ActionSetChannelSelect',
      'ActionSetFilterChannel',
      'ActionSetDialogChannel'
    ]),

    prepareItenCSV(itens) {
      function checkStatus(status) {
        let value;
        switch (status) {
          case 2:
            value = 'Pendente';
            break;

          case 3:
            value = 'Ativo';
            break;

          case 4:
            value = 'Pausado';
            break;

          default:
            value = '-';
            break;
        }
        return value;
      }
      return itens.map((item) => {
        return {
          ...item,
          status: checkStatus(item.status)
        };
      });
    },

    async getMyChannels() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('v2/redirect?app=affiliate&path=/api/v2/get/affiliate/site');
        if (status === 200) this.ActionSetChannels(data);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getChannels(page, perPage) {
      let query;
      if (!this.filterChannel) query = '';
      else query = `${this.filterChannel}%26`;
      if (!page) query += 'page=1';
      else query += page;
      if (!perPage) query += '%26perPage=10';
      else query += perPage;
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=affiliate&path=/api/get/site/search?' + query);
        if (status === 200) {
          this.ActionSetChannels(data.data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          name: 'id',
          required: true,
          label: 'ID',
          align: 'left',
          field: 'id',
          sortable: true
        },
        // {
        //     name: "affiliate_id",
        //     required: true,
        //     label: "ID do afiliado",
        //     align: "left",
        //     field: "affiliate_id",
        //     sortable: true,
        // },
        {
          name: 'cj_id',
          label: 'CJ ID',
          align: 'left',
          field: 'cj_id',
          sortable: true
        },
        {
          name: 'affiliate_name',
          align: 'left',
          label: 'Afiliado',
          field: 'affiliate_name',
          sortable: true
        },
        {
          name: 'affiliate_email',
          align: 'left',
          label: 'Email do afiliado',
          field: 'affiliate_email',
          sortable: true
        },
        {
          name: 'name',
          align: 'left',
          label: 'Nome do canal',
          field: 'name',
          sortable: true
        },
        {
          name: 'url',
          align: 'left',
          label: 'Url do canal',
          field: 'url',
          sortable: true
        },
        {
          name: 'category',
          align: 'left',
          label: 'Categoria',
          field: 'category'
        },
        {
          name: 'type_name',
          align: 'left',
          label: 'Vertical',
          field: 'type_name'
        },
        {
          align: 'left',
          name: 'status',
          label: 'Status',
          field: 'status'
        },
        {
          name: 'created_at',
          align: 'center',
          label: 'Data de cadastro',
          field: 'created_at'
        },
        {
          name: 'action',
          align: 'center',
          label: 'Ações',
          field: 'id'
        }
      ];
    },
    ...mapGetters('Channel', ['channels', 'channelSelect', 'filterChannel', 'dialogChannel'])
  }
};
